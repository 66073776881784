<template>
	<div
		class="pa-20 color-white overflow-y-auto"
		style="background: #08080D; overflow-x: hidden"
	>
		<div class="flex-row justify_content_between align_items_center mb-20" style="height: 30px;">
			<p class="size-px-20">Launchpad</p>
			<button class="color-white" @click="$router.back()"><i class="icon icon-close size-px-15"></i></button>
		</div>
		<div>

			<div>
				<div>
					<img
						:src="img_list" alt="nft"
						@error="$bus.$emit('onErrorImage', $event)"
						style="width: 100%; max-height: 355px; border-radius: 20px;"
					>
				</div>
				<div class="mt-10">
					<p style="color: #BBB;">{{ detail.sl_startdt }} ~ {{ detail.sl_enddate }}</p>
					<h1 class="mt-5" style="word-break: break-all">{{ detail.nft_sl_name }}</h1>
					<p class="mt-5" style="color: #BBB;">{{ detail.cartl_name }}</p>
					<p class="mt-5 size-px-13" v-if="detail.sl_state_code === 'SA00200003'">{{ $language.launchpad.selling_price }} <!-- 판매가 -->
						<span class="size-px-15 font-weight-600">&#x20A9;{{ detail.sl_basic_price | makeComma }}</span>
					</p>
				</div>
			</div>
			<hr class="mt-20 mb-20" style="border: 1px solid #181A39">
			<div class="flex-row justify_content_between">
				<p class="size-px-13">
					{{ $language.launchpad.currently }}
					<span class="size-px-16" style="font-weight: 700; color: #3E4787;">{{detail.sl_compl_quantity }} {{ $language.launchpad.gae }}</span>
					{{ $language.launchpad.have_been_sold }}
				</p>
				<div>
					<p class="size-px-13">
						<span style="color: #3E4787;">{{ detail.sl_compl_quantity }}</span> / {{ detail.sl_quantity | makeComma }}
					</p>
				</div>
			</div>
			<div class="full-width mt-10" ref="progress">
				<progress-bar
						:diameter="200"
						:completed-steps="completedSteps"
						:total-steps="totalSteps"
						:show-tip="false"
						:innerStrokeColor="'#24264E'"
						:startColor="'#3E47B7'"
						:stopColor="'#3E47B7'"
						:width="width"
						:height="15"
				>
				</progress-bar>
			</div>
			<ul class="mt-20" v-if="detail.sl_state_code === 'SA00200003'">
				<li class="flex-row justify_content_between align_items_center mt-10"
						style="background: #24264E; padding: 14px; border-radius: 8px" @click="onPaymentList">
					<div>
						<p class="size-px-12 mb-5" style="opacity: 0.3;">{{ $language.launchpad.select_payment }}</p>
						<!-- 결제 수단 선택 -->
						<p class="size-px-16">{{
								item_payment.payment_money_code ? item_payment.payment_money_name : $language.launchpad.select_payment_input
							}}</p>
					</div>
					<p><i class="icon icon-arrow-right size-px-15" style="opacity: 0.3;"></i></p>
				</li>
				<li v-if="item_payment.payment_money_code === 'PM01100200'"
						class="flex-row justify_content_between align_items_center mt-10"
						style="background: #24264E; padding: 14px; border-radius: 8px" @click="is_on_credit = true"
				>
					<div>
						<p class="size-px-12 mb-5" style="opacity: 0.3;">{{ $language.launchpad.select_card }}</p><!-- 신용카드 선택 -->
						<p
							v-if="!item_credit.payment_mthd_name"
							class="size-px-16"
						>{{ $language.launchpad.select_card_input }}</p>
						<div class="flex-row align_items_center" v-else>
							<img src="@/assets/image/cartel-cover-thumb.png" alt="credit_img" style="width: 20px; height: 20px; border-radius: 50%" >
							<p class="size-px-16 ml-10">
								{{ item_credit.payment_mthd_name }} ({{
									item_credit.payment_mthd_number.slice(-4).replaceAll('X', '')
								}})
							</p>
						</div>
					</div>
					<p><i class="icon icon-arrow-right size-px-15" style="opacity: 0.3;"></i></p>
				</li>
				<li v-if="item_payment.payment_money_code"
						class="flex-row justify_content_between align_items_center mt-10"
						style="background: #24264E; padding: 14px; border-radius: 8px">
					<div>
						<p class="size-px-12 mb-5" style="opacity: 0.3;">{{ $language.launchpad.select_quantity }}</p><!-- 수량 선택 -->
						<div class="flex-row align_items_center">
							<img src="@/assets/image/cartel-cover-thumb.png" alt="credit_img" style="width: 20px; height: 20px; border-radius: 50%">
							<p class="size-px-16 ml-10">
								{{ bigTimes(item_payment.payment_amount_quantity, quantity) | makeComma(8) }}
								{{
									item_payment.payment_money_code !== 'PM01100200' && item_payment.payment_money_code !== 'PM01100300' ? item_payment.payment_money_name : '원'
								}}
							</p>
						</div>
					</div>
					<div class="flex-row align_items_center">
						<button class="btn_circle mr-10" :disabled="quantity === 1" @click="quantity--">
							<font-awesome-icon :icon="['fas', 'minus']"/>
						</button>
						<span class="size-px-16">{{ quantity | makeComma(8) }}</span>
						<button class="btn_circle ml-10" @click="quantity++" :disabled="is_ios_app">
							<font-awesome-icon :icon="['fas', 'plus']"/>
						</button>
					</div>
				</li>
				<li v-if="item_payment.payment_money_code"
						class="mt-10"
						style="background: #24264E; padding: 14px; border-radius: 8px">
					<div class="flex-row align_items_center justify_content_between">
						<span class="size-px-16" v-if="item_payment.payment_money_code === 'PM01100200' || item_payment.payment_money_code === 'PM01100300'">
							{{ $language.launchpad.last_payment_price }}</span><!-- 최종 결제 금액 -->
						<span class="size-px-16" v-else>{{ $language.launchpad.last_payment_quantity }}</span><!-- 최종 결제 수량 -->
						<span class="size-px-16">
							<span>
								{{ bigTimes(item_payment.last_payment_amount_quantity, quantity) | makeComma(8) }}
							</span>
							{{ item_payment.payment_money_code !== 'PM01100200' && item_payment.payment_money_code !== 'PM01100300' ? item_payment.payment_money_name : '원' }}
						</span>
					</div>
					<hr class="mt-10 mb-10" style="border: 1px solid rgba(255, 255, 255, 0.05)">
					<div class="flex-row align_items_center justify_content_between mb-5">
						<span class="size-px-14" style="opacity: 0.7"
									v-if="item_payment.payment_money_code === 'PM01100200' || item_payment.payment_money_code === 'PM01100300'">
							{{ $language.launchpad.payment_price }}</span><!-- 결제 금액 -->
						<span class="size-px-14" style="opacity: 0.7" v-else>{{ $language.launchpad.payment_quantity }}</span>
						<!-- 결제 수량 -->
						<span class="size-px-14" style="opacity: 0.7">
							<span>{{ bigTimes(item_payment.payment_amount_quantity, quantity) | makeComma(8) }}</span>
							{{ item_payment.payment_money_code !== 'PM01100200' && item_payment.payment_money_code !== 'PM01100300' ? item_payment.payment_money_name : '원' }}
						</span>
					</div>
					<div
						class="flex-row align_items_center justify_content_between mb-5"
						v-if="item_payment.payment_money_code === 'PM01100200'"
					>
						<span class="size-px-14" style="opacity: 0.7">{{ $language.launchpad.vat }}</span><!-- 부가세 -->
						<span class="size-px-14" style="opacity: 0.7">
							{{ item_payment.vat * quantity | makeComma }}원
						</span>
					</div>
					<div class="flex-row align_items_center justify_content_between">
						<span class="size-px-14" style="opacity: 0.7">{{ $language.launchpad.basic_discount }}</span><!-- 기본 할인 -->
						<span class="size-px-14" style="opacity: 0.7">
							{{
								(item_payment.dc_amount_quantity
										? item_payment.dc_method_code === 'PM00700002' && item_payment.payment_money_code !== 'PM01100300'
												? (bigTimes(bigTimes(item_payment.payment_amount_quantity, item_payment.dc_amount_quantity / 100), quantity))
												: bigTimes(item_payment.dc_amount_quantity, quantity)
										: 0) | makeComma(8)
							}}
							{{
								item_payment.payment_money_code !== 'PM01100200' && item_payment.payment_money_code !== 'PM01100300'
								? item_payment.payment_money_name
								: '원'
							}}
												</span>
					</div>
				</li>
			</ul>
			<div class="mt-20" style="color: #888;">
				<p class="size-px-16" style="color: #BBB;">{{ $language.launchpad.purchase_guide_title }}</p>
				<ul class="size-px-13" style="margin-top: 14px">
					<li>· {{ $language.launchpad.purchase_guide_1 }}</li>
					<li>· {{ $language.launchpad.purchase_guide_2 }}</li>
					<li>· {{ $language.launchpad.purchase_guide_3 }}</li>
					<li>· {{ $language.launchpad.purchase_guide_4 }}</li>
				</ul>
			</div>
			<button
				class="btn_blue"
				@click="$router.push({ name: 'mafia049', params: { idx: detail.cartl_number, from: $route.fullPath}} )"
			>{{ $language.launchpad.visit_cartel }} <!-- 카르텔 방문하기 --></button>
			<hr class="mt-20 mb-20" style="border: 1px solid #181A39">
			<div style="margin-bottom: 70px">
				<p class="size-px-13 mb-10">{{ detail.sl_guide }}</p>
				<img v-for="img in img_contents_list" :key="img.img_indexnumber" :src="img.img_file_path" alt="nft_detail" style="width: 100%;">
			</div>
		</div>
		<div class="bottom_btn">
			<button @click="onPin" :disabled="detail.sl_state_code !== 'SA00200003'|| purchase_disabled" :style="detail.sl_state_code !== 'SA00200003' || purchase_disabled? 'opacity: 0.6' : '' ">{{ sale_state }}</button><!-- 구매하기 -->
		</div>

		<Popup_select
				v-if="is_on_payment"
				@cancel="is_on_payment = false"
		>
			<template
					v-slot:title
			>{{ $language.point_result.title_payment_type }}
			</template>
			<template
					v-slot:list
			>
				<li v-if="!detail.payment_mthd_list">{{ $language.point_result.none_payment_type }}</li>
				<li
						v-else
						v-for="(payment, p_index) in list_purchase"
						:key="'payment_' + p_index"
						class="ptb-10 mb-10"
						@click="setPayment(payment)"
				>{{ payment.payment_money_name }}
				</li>
			</template>
		</Popup_select>
		<MyCredit
				v-if="is_on_credit"
				:user="user"
				@click="setCredit"
				@cancel="is_on_credit = false"
		></MyCredit>

	</div>
</template>

<script>
import ProgressBar from "vue2-progress-bar";
import MyCredit from "@/view/Mypage/MyCredit";
import Popup_select from "@/view/Layout/PopupSelect.vue";
import Big from "big.js";

export default {
	name: "SaleNftDetail"
	, props: ['user', 'show']
	, components: {
		Popup_select,
		ProgressBar,
		MyCredit
	}
	, data() {
		return {
			program: {
				name: 'SaleNftDetail'
				, type: 'SaleNftDetail'
				, not_footer: true
				, not_header: true
			}
			, nft_number: this.$route.params.nft_number
			, state_code: this.$route.params.state_code
			, items: []
			, completedSteps: 0
			, totalSteps: 0
			, width: 500
			, is_on_credit: false
			, is_on_payment: false
			, pinnumber: ''
			, quantity: 1
			, card_type: ''
			, item_select_coin: {
				sl_coin_token_name: ''
			}
			, is_detail: false
			, detail: []
			, item_payment: []
			, item_credit: []
			, point_charg_number: ''
			, is_on_purchase_cancel: false
			, img_list: ''
			, img_contents_list: ''
		}
	}

	, computed: {
		list_purchase: function () {
			console.log('list_purchase', this.detail.payment_mthd_list)
			if(!this.detail.payment_mthd_list){
				// return
			}
			return this.detail.payment_mthd_list.filter((item) => {
				return item
			})
		}
		, list_purchase2: function () {
			console.log('list_purchase', this.detail.payment_mthd_list)
			if(!this.detail.payment_mthd_list){
				// return
			}
			return this.detail.payment_mthd_list.filter((item) => {
				if (this.is_ios_app) {
					if (item.payment_money_code == 'PM01100300') {
						return item
					}
				} else {
					if (item.payment_money_code != 'PM01100300') {
						return item
					}
				}
			})
		}
		, sale_state: function () {
			let t = ''
			switch (this.detail.sl_state_code) {
				case 'SA00200001':
					t = this.$language.launchpad.txt_register
					break;
				case 'SA00200002':
					t = this.$language.launchpad.txt_coming_soon
					break;
				case 'SA00200003':
					t = this.$language.launchpad.txt_purchase
					break;
				case 'SA00200004':
					t = this.$language.launchpad.txt_minting_ended
					break;
				case 'SA00200005':
					t = 'SOLD OUT'
					break;
			}
			return t
		}
		, is_ios_app: function () {
			let t = false
			if (this.$common.isApp() && this.$common.getMobile() == 'ios') {
				t = true
			}
			t = false
			return t
		}
		, purchase_disabled: function () {
			let t = ''
			if (!this.item_payment.payment_money_code || Number(this.quantity) < 1) {
				t = true
			} else t = this.item_payment.payment_money_code === 'PM01100200' && !this.item_credit.payment_mthd_number;
			return t
		}
	},
	methods: {
		setProgress() {
			const val = this.$refs.progress.offsetWidth
			console.log(val,'offsetWidth')
			this.completedSteps = Number(this.detail.sl_compl_quantity)
			this.totalSteps = Number(this.detail.sl_quantity)

			if (!val || isNaN(val)) {
				return false;
			}

			this.width = parseInt(val);
		}
		, onPaymentList: function (type) {
			this.is_on_payment = type
		}
		, setPayment: function (item) {
			this.item_payment = item
			this.$set(this.item_payment, 'point_charg_number', item.ios_inapp_price_id)
			this.is_on_payment = false
			if (item.payment_money_code == 'PM01100300') {
				this.quantity = 1
			}
			// this.getPaymentType()
		}
		, setCredit: function (credit) {
			console.log(credit, 'credit')
			this.item_credit = credit
			this.is_on_credit = false
		}
		, onPin: function () {
			if (!this.item_payment.payment_money_code) {
				this.$bus.$emit('notify', {type: 'error', message: this.$language.launchpad.select_payment_input})
				return
			}
			if (this.item_payment.payment_money_code === 'PM01100200' && !this.item_credit.payment_mthd_number) {
				this.$bus.$emit('notify', {type: 'error', message: this.$language.launchpad.select_card_input})
				return
			}
			if (Number(this.quantity) < 1) {
				this.$bus.$emit('notify', {type: 'error', message: '수량을 정확히 입력하세요'})
				return
			}
			if (this.is_ios_app) {
				this.$bus.$emit('on', true)
				this.$common.inAppWebviewCommunity('android', 'inAppIosPurchase:' + this.item_payment.point_charg_number)
			} else {
				this.$bus.$off('pinCallback')
				this.$bus.$on('pinCallback', (type, pin) => {
					console.log(type, pin)
					this.pinnumber = pin
					this.postBuyNft()
				})
				this.$bus.$emit('onPin', 'check')
			}
		}
		, bigTimes: function (a, b) {
			a = Number(a)
			b = Number(b)
			let price = new Big(a)
			return String(price.times(b))
		}
		, bigMinus: function (a, b) {
			a = new Big(Number(a))
			b = new Big(Number(b))
			return a.minus(b)
		}
		, postBuyNft: async function () {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_sale_nft_purchase
					, data: {
						member_number: this.user.member_number
						, cartl_nft_sl_number: this.nft_number
						, pinnumber: this.pinnumber
						, purchase_quantity: this.quantity
						, sl_price_quantity: this.item_payment.payment_amount_quantity
						, payment_money_code: this.item_payment.payment_money_code
						, payment_mthd_number: this.item_credit.payment_mthd_number
						, last_payment_amount_quantity: this.bigTimes(this.item_payment.last_payment_amount_quantity, this.quantity)
						, payment_amount_quantity: this.bigTimes(this.item_payment.payment_amount_quantity, this.quantity)
						, dc_amount_quantity: this.item_payment.dc_method_code === 'PM00700002'
								? (this.bigTimes(this.bigTimes(this.item_payment.payment_amount_quantity, this.item_payment.dc_amount_quantity / 100), this.quantity))
								: this.bigTimes(this.item_payment.dc_amount_quantity, this.quantity)
						, vat: Number(this.bigTimes(this.item_payment.vat, this.quantity))
					}
					, type: true
				})

				if (result.success) {
					console.log('result', result)
					if (result.result.data.resultinfo.result_div_cd === 'ERR') {
						this.$bus.$emit('notify', {type: 'error', message: result.result.data.resultinfo.result_msg})
						return
					}
					if (result.result.data.d.cartl_nft_purchase_list) {
						await this.$router.push({
							name: 'PurchaseNft',
							params: {
								items: result.result.data.d
							}
						})
					}
				} else {
					throw result.message
				}
			} catch (e) {
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)	
			}
		}
		, setItem: function (item) {
			console.log('setItem', item)
			this.item_select_coin = item
			this.is_on_payment = false
			this.blockchain_div_code = item.blockchain_div_code
			if (this.card_type == 'nft') {
				this.toBuyNft()
			}
		}
		, getNftDetail: async function () {
			console.log(this.state_code, 'state code')
			let detail_url
			if (this.state_code === 'SA00200003'){
				detail_url = this.$api_url.api_path.get_sale_nft_detail
			} else {
				detail_url = this.$api_url.api_path.get_not_sale_nft_list
			}
				try {
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: detail_url
						, data: {
							member_number: this.user.member_number
							, cartl_nft_sl_number: this.nft_number
						}
						, type: true
					})

					if (result.success) {
						console.log('123123123')
						if (result.result.data.resultinfo.result_div_cd === 'ERR') {
							this.$bus.$emit('notify', {type: 'error', message: result.result.data.resultinfo.result_msg})
							return
						}
						console.log('result detail', result.result.data.d)
						this.detail = result.result.data.d
						this.detail.cartl_nft_sl_img_list.forEach((item) => {
							switch (item.exposure_position_code) {
								case 'SA00300001' :
									this.img_list = item.sl_img_partial_list[0].img_file_path
									break;
								case 'SA00300002' :
									this.img_contents_list = item.sl_img_partial_list
									break;
							}
						})
						this.setProgress()
						await this.inAppPurchase()
					} else {
						throw result.message
					}
				} catch (e) {
					this.$bus.$emit('notify', {type: 'error', message: e})
				} finally {
					this.$bus.$emit('on', false)
				}
		}

		, inAppPurchase: async function () {

			if (this.is_ios_app) {
				try {
					this.$common.inAppWebviewCommunity('android', 'inAppIos')
					window.purchase = async () => {
						console.log('window.purchase')
						this.$bus.$emit('notify', {type: 'success', message: '결제처리중입니다.'})
						this.is_on_purchase_cancel = true
					}
					window.purchase_pending = async () => {
						console.log('window.purchase_pending')
						this.$bus.$emit('notify', {type: 'success', message: '결제요청중입니다.'})
						this.is_on_purchase_cancel = true
					}
					window.not_purchase = async () => {
						console.log('window.not_purchase')
						this.$bus.$emit('notify', {type: 'error', message: '결제가능한 상품이 없습니다.'})
						this.$bus.$emit('on', false)
						this.is_on_purchase_cancel = false
					}
					window.purchase_confirm = async (data) => {
						console.log('window.purchase_confirm')
						console.log(this.item_payment, data)
						/*
						if(!this.item_payment.point_charg_number) {
							this.$bus.$emit('notify', { type: 'success', message: '완료되지 않은 결제건을 진행합니다'})
							console.log(' in items ', this.items)
							this.items.filter( async (item) => {
								console.log(item.point_charg_number + ' : ' + data.productID)
								if(item.point_charg_number == data.productID){
									this.item_payment = item
									if (data.result) {
										await this.postConfirmIos(data)
									} else {
										this.$bus.$emit('notify', {type: 'error', message: data.message})
									}
								}
							})
						}else{
							if (data.result) {
								await this.postConfirmIos(data)
							} else {
								this.$bus.$emit('notify', {type: 'error', message: data.message})
							}
						}

						 */
					}
					window.purchase_complete = async (data) => {
						console.log('window.purchase_complete')
						console.log(data)
						// await this.postCompleteIos(data)
					}
					window.purchase_result = async (data) => {
						console.log('window.purchase_result')
						console.log(data)
					}
					window.purchase_cancel = async () => {
						console.log('window.purchase_cancel')
						this.offPurchase()

					}
				} catch (e) {
					console.log(e)
				}
			}
		}
		, offPurchase: function () {
			this.$bus.$emit('on', false)
			this.is_on_purchase_cancel = false;
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getNftDetail()
		console.log(this.user, 'user data')
	},
}
</script>

<style scoped>
li {
	margin-bottom: 6px;
}

.btn_blue {
	width: 100%;
	margin-top: 20px;
	padding: 12px;
	color: white;
	background: #293097;
	border-radius: 25px;
	font-size: 16px;
	text-align: center;
}

.bottom_btn {
	background: #181A39;
	width: 100%;
	z-index: 10;
	position: fixed;
	right: 0;
	left: 0;
	bottom: 0;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 30px 30px 0 0;
}

.bottom_btn button {
	color: white;
	font-size: 16px;
	font-weight: 600;
}

.btn_circle {
	width: 30px;
	height: 30px;
	background: #293097;
	border-radius: 100%;
	padding: 5px;
	color: white;
}
</style>